import { Fade } from '@mui/material';
import React, { useEffect } from 'react'
import { AppName } from '../../Branding';
import NavBarComponent from '../../Components/NavBarComponent';

function HomeLayout({ children, activeRoute, title, fadeNavIn }: { children?: React.ReactNode | React.ReactNode[], activeRoute?: any, title?: string, fadeNavIn?: boolean }) {
  useEffect(() => {
    if (title && title?.toString().length > 0 && !title.toString().includes("undefined")) {
      document.title = `${AppName} | ${title}` || AppName;
    } else {
      document.title = AppName;
    }
  }, [title]);
  return (
    <div>
      {fadeNavIn !== undefined ? <Fade in={true} timeout={2000}><div><NavBarComponentHolder activeRoute={activeRoute}/></div></Fade> : <NavBarComponentHolder activeRoute={activeRoute} />}
      {children}
    </div>
  )
}

function NavBarComponentHolder({activeRoute}: {activeRoute?: any}) {
  return (
    <NavBarComponent activeRoute={activeRoute} />
  )
}

export default HomeLayout
import React from 'react'
import { theme } from '../..';

import bnuybg_mp4 from "../../img/bnuybg.mp4";

function BnuyBg({height, children}: {height?: string, children?: any}) {
  return (
    <div
      style={{
        height: height,
        width: "100vw",
        top: "4rem", left: 0,
        overflow:"hidden",
        position: "absolute",
        clip: "rect(0, auto, auto, 0)",
      }}
    >
      <video
        playsInline
        autoPlay
        muted
        loop
        // poster="bnuybg.jpg"
        // id="bgvid"
        style={{
          objectFit: "cover",
          width: "100vw",
          position: "fixed",
          marginTop: window.innerWidth > theme.breakpoints.values.sm ? "-50vw" : "inherit",
          top: 0,
          left: 0,
          zIndex: -10,
        }}
      >
        {/* <source src="bnuybg.webm" type="video/webm" /> */}
        <source src={bnuybg_mp4} type="video/mp4" />
      </video>
      <div
        style={{
          zIndex: 100,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default BnuyBg
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { appLogo, theme } from '../..';
// import useImagePreloader from '../../hooks/useImagePreloader';
import HomeLayout from '../../Layouts/HomeLayout';

import apple_hero_img_1 from "../../img/hero__dvsxv8smkkgi_large.jpg";
import BnuyBg from '../../Components/BnuyBg';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase-config';

function Home() {
  // const preloadSrcList: string[] = [

  // ];
  // const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_home');
  }, []);
  return (
    <HomeLayout activeRoute={"/"}>
      <Box
        sx={{
          height: "700px",
          // background: "#010127",
          padding: "1em",
          marginBottom: "1rem",
        }}
      >
        <BnuyBg height={"750px"}>
          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
              alignContent: "center",
              marginTop: "2rem",
            }}
          >
            <Typography variant={"h2"} sx={{ color: "#fbfbfd" }}>iBnuy</Typography>
            <Typography variant={"h4"} sx={{ color: "#fbfbfd" }}>Is Bun. That's It.</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
                marginLeft: "auto", marginRight: "auto",
                justifyContent: "center"
              }}
            >
              <Typography variant={"h5"}>
                <a href="https://astrabun.com/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main, textDecoration: "none" }}>{"Learn More >"}</a>
              </Typography>
              <Typography variant={"h5"}>
                <a href="https://astrabun.com/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main, textDecoration: "none" }}>{"Buy >"}</a>
              </Typography>
            </Box>
          </Box>
        </BnuyBg>
      </Box>
      <Box
        sx={{
          height: "75vh",
          background: "#010127",
          padding: "1em",
          marginBottom: "1rem",
          zIndex: "1"
        }}
      >
        {/* https://www.petplan.co.uk/images/breeds/netherland-dwarf.png */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            zIndex: "1"
          }}
        >
          <img src="https://www.petplan.co.uk/images/breeds/netherland-dwarf.png" alt="bun" style={{ maxWidth: "100%", marginLeft: "auto", marginRight: "auto" }} />
          <Typography variant={"h3"} sx={{ color: "#fbfbfd" }}>It's Bun or Never</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "624px",
          background: `#010127 url(${apple_hero_img_1})`,
          backgroundSize: "3008px 624px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "1em",
          marginBottom: "1rem",
          zIndex: "1"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            zIndex: "1"
          }}
        >
          <Typography variant={"h3"} sx={{ color: "#fbfbfd", marginTop: "2rem" }}><img src={appLogo} alt="Logo" style={{ height: theme.typography.h3.fontSize, marginRight: "1rem" }} />Hop</Typography>
          <Typography variant={"h5"} sx={{color: "#fbfbfd"}}>Save 5% on Bun products with a Bun Card through December 25.</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              marginLeft: "auto", marginRight: "auto",
              justifyContent: "center"
            }}
          >
            <Typography variant={"h5"}>
              <a href="https://astrabun.com/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main, textDecoration: "none" }}>{"Learn More >"}</a>
            </Typography>
            <Typography variant={"h5"}>
              <a href="https://astrabun.com/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main, textDecoration: "none" }}>{"Buy >"}</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  )
}

export default Home